<template>
  <div style="max-width: 100%; display: inline-block; position: relative;">
    <sp-input :placeholder="placeholder" :value="fileName" readonly style="width: calc(100% - 100px)"/>
    <input
    ref="fileInput"
    @change="fileChoose"
    type="file"

    style="
    border-radius: 5px;
    box-sizing: border-box;
    height:28px;
    margin:2.5px 2.5px 2.5px 2.5px;
    border: 4px solid red;
    padding: 0;
    position:absolute;
    width :100%;
    opacity: 0;
    right:0"/>
    <sp-button
    style="pointer-events: none; width :100px; opacity: 1"
    class="primary">파일 찾기</sp-button>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: null, default: undefined },
    allowedExt: {
      type: Array,
      default: () => [],
    },
    maxFileSize: {
      type: Number,
      default: 1048576,
    },
    placeholder: {
      type: String,
      default: '파일을 등록해 주세요.',
    },
  },
  name: 'SpFileInput',
  created () {

  },
  data () {
    return {
      file: null,
      preview: '',
      fileName: '',
      displayFileChooser: false,
    }
  },
  computed: {
    allowedExtLower () {
      return this.allowedExt.map(e => e.toLowerCase())
    },
  },
  methods: {
    async fileChoose (e) {
      const file = e.target.files[0]
      if (file) {
        const ext = file.name.split('.').pop().toLowerCase()
        const size = file.size
        // fileExt
        if (this.allowedExt.length) {
          if (!this.allowedExtLower.includes(ext)) {
            await this.alert({ title: '파일 선택', message: `${this.allowedExt.join(', ')} 파일만 등록 가능합니다.` })
            this.$refs.fileInput.value = null
            return
          }
        }
        // fileSize
        if (size > this.maxFileSize) {
          await this.alert({ title: '파일 선택', message: `최대 ${this.formatBytes(this.maxFileSize)} 까지 등록 가능합니다.` })
          this.$refs.fileInput.value = null
          return
        }
        this.preview = URL.createObjectURL(file)
      } else {
        this.preview = ''
        this.fileName = ''
      }
      this.fileName = file.name
      this.file = file
      this.$emit('input', this.file)
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
