const getDefaultState = () => {
  return {
    buildings: [],
    building: {},
    buildingFloors: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    buildings: (state) => state.buildings,
    building: (state) => state.building,
    buildingFloors: (state) => state.buildingFloors,
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setBuildings (state, data) {
      state.buildings = data.buildings
    },
    setBuilding (state, data) {
      state.building = data.building
    },
    setBuildingFloors (state, data) {
      state.buildingFloors = data.buildingFloors
    },
  },
  actions: {
    async getBuildings ({ commit }) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.OMS_API_URL}buildings`)
        commit('setBuildings', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBuilding ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.OMS_API_URL}building/${payload.buildingNo}`,
        )
        commit('setBuilding', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBuildingFloors ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.OMS_API_URL}building/${payload.buildingNo}/floors`,
        )
        commit('setBuildingFloors', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAllBuildingsFloors ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.OMS_API_URL}all-buildings-floors`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
