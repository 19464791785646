<template>
    <span class="badge">NEW</span>
</template>
<script>
export default {
  name: 'IconNew',
}
</script>
<style lang="scss" scoped>
.badge {
    display: inline-block;
    margin: 1px;
    padding: 1px 3px;
    font-size: 12px;
    color: #fff;
    line-height: 14px;
    border-radius: 3px;
    background-color: red;
}
</style>
