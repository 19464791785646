
const validity = {
  install: function (Vue) {
    Vue.directive('validity', function (el, binding) {
      const HTMLFormElements = ['SELECT', 'INPUT', 'TEXTAREA']
      if (HTMLFormElements.includes(el.tagName)) {
        el.setCustomValidity(binding.value ? binding.value : '')
        el.setAttribute('validation-message', binding.value ? binding.value : '')
      } else {
        const cel = el.querySelector('input, select, textarea')
        cel.setCustomValidity(binding.value ? binding.value : '')
        cel.setAttribute(
          'validation-message',
          binding.value ? binding.value : '',
        )
      }
    })
  },
}
export default validity
