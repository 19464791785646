<template>
    <sup><span class="sr-only">(필수)</span></sup>
</template>
<script>
export default {
  name: 'IconRequired',
}
</script>
<style lang="scss" scoped>
sup {
  display: inline-block;
  top: auto;
  font-size: 0.8rem;
  // margin-left: 0.3rem;
  color: #212121;
  &:before {
    display: block;
    content: '*';
  }
}
</style>~
