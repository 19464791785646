import Excel from 'exceljs'
import streamSaver from 'streamsaver'
import Vue from 'vue'

Vue.mixin({
  data () {
    return {
      debounceTimer: null,
    }
  },
  methods: {
    log (e, name = '>>>') {
      console.log(name, this.JSONPrettyPrint(e))
      return e
    },
    /**
     * 숫자 3자리마다 쉼표찍어 반환
     *
     * @param val Number 숫자
     * @return String
     */
    numberFormat (val) {
      return this.getFormattedNumber(val)
    },
    formatBytes (bytes) {
      if (bytes < 1024) {
        return bytes + ' Byte'
      } else if (bytes < 1048576) {
        const kilobytes = (bytes / 1024).toFixed(2)
        return kilobytes + ' KB'
      } else if (bytes < 1073741824) {
        const megabytes = (bytes / 1048576).toFixed(2)
        return megabytes + ' MB'
      } else {
        const gigabytes = (bytes / 1073741824).toFixed(2)
        return gigabytes + ' GB'
      }
    },
    getFormattedNumber (val) {
      if (typeof val !== 'number') {
        return '0'
      }
      const isMinus = val < 0
      const formattedValue = String(Math.abs(val))
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      return isMinus ? `-${formattedValue}` : formattedValue
    },
    getFormattedPhoneNumber (val) {
      return val.replace(/(\d{3})([\d*]{3,4})([\d*]{4})/, '$1-$2-$3')
    },
    getFormatResidentNumber (data) {
      // 숫자만 추출하여 형식에 맞게 "-" 문자를 추가
      return data.replace(/\D/g, '').replace(/(\d{6})(\d{7})/, '$1-$2')
    },
    getFormattedBusinessNumber (val) {
      // 입력 = 1234567890
      // 출력 = 123-45-67890
      const data = val || 0
      const stringOnly = data.toString()
      return stringOnly.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')
    },
    JSONPrettyPrint (v) {
      return JSON.stringify(v, null, 2)
    },
    deepCopy (obj) {
      // 객체 deepCopy
      return JSON.parse(JSON.stringify(obj))
    },
    getIndexNo (totalCount, index, limit, page) {
      return totalCount - (page - 1) * limit - index
    },
    getTodayDate (date = new Date()) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    },
    debounce (fn, wait) {
      return (...args) => {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer) // clear any pre-existing timer
        }
        const context = this // get the current context
        this.debounceTimer = setTimeout(() => {
          fn.apply(context, args) // call the function if time expires
        }, wait)
      }
    },
    throttle (fn, wait) {
      let throttled = false
      return function (...args) {
        if (!throttled) {
          fn.apply(this, args)
          throttled = true
          setTimeout(() => {
            throttled = false
          }, wait)
        }
      }
    },
    getCookie (name) {
      const matches = document.cookie.match(
        new RegExp(
          '(?:^|; )'
            + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')
            + '=([^;]*)',
        ),
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    setCookie (name, value, options = {}) {
      options = {
        path: '/',
        // 필요한 경우, 옵션 기본값을 설정할 수도 있습니다.
        ...options,
      }

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString()
      }

      let updatedCookie
        = encodeURIComponent(name) + '=' + encodeURIComponent(value)

      for (const optionKey in options) {
        updatedCookie += '; ' + optionKey
        const optionValue = options[optionKey]
        if (optionValue !== true) {
          updatedCookie += '=' + optionValue
        }
      }

      document.cookie = updatedCookie
    },
    deleteCookie (name) {
      this.setCookie(name, '', {
        'max-age': -1,
      })
    },
    /**
     * 엑셀 파일 읽어오기
     *
     * 급하게 새로 짯는데 성능 테스트는 안해봣습니다.
     * 누가 10만 row 이상 엑셀 읽기 테스트 해주실분? (성능이야 사용자 컴퓨터 성능에 달렸겠지만...)
     *
     * @param data input type=file e.target.files[0]
     * (인풋태그 file을 전달하면 Array of Object 로 밷음)
     */
    async readFromExcel (data) {
      const workbook = new Excel.Workbook()
      await workbook.xlsx.load(data)

      const worksheet = workbook.getWorksheet(1)
      const headers = worksheet.getRow(1).values
      const sheetData = []

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) {
          const rowData = {}
          headers.forEach((header) => {
            rowData[header] = null
          })
          row.eachCell((cell, colNumber) => {
            let cellValue = cell.value

            // Handle hyperlink or mail as plain text
            if (
              cell.type === Excel.ValueType.Hyperlink
              || cell.type === Excel.ValueType.Email
            ) {
              cellValue = cell.text
            }
            rowData[headers[colNumber]] = cellValue
          })
          sheetData.push(rowData)
        }
      })

      return sheetData
    },
    async getExcelHearder (data) {
      const workbook = new Excel.Workbook()
      await workbook.xlsx.load(data)

      const worksheet = workbook.getWorksheet(1)
      const headers = worksheet.getRow(1).values

      return headers
    },
    /**
     * 엑셀 생성 (Frontend)
     * <!> 참고사항 <!>
     * 이놈은 임시적으로 간단한 파일 생성에만 사용하는 것입니다.
     * [광고] 대용량 다운로드는? excelerator!
     *
     * @param header Array 타이틀 항목
     * @param fileName String 파일 이름
     * @param dataList Array 엑셀 데이터
     * @param sheetNm String 시트명
     * @param widthList Array width 데이터
     * @param rowPerFile Nunber 분할 다운로드 시 파일당 record 수
     *                   (데이터 10만 이상 생성 시 브라우저 크래시 위험 있음)  앗, 이런!
     *                   서비스워커 스트리밍 처리로 최대한 성능을 개선했지만 단일파일 10row가 한계선이다
     * @param lastAlert Boolean 완료시 알림 여부
     * @param stringTypeColumns Array column명 or index
     *                         (앞에 0이 들어가야하는 텍스트형 숫자로 인식되어야 하는 컬럼명)
     */
    async createExcel (
      header,
      fileName,
      dataList,
      sheetNm,
      widthList = [],
      stringTypeColumns = [],
      rowPerFile = 100000,
      lastAlert = true,
    ) {
      const sleep = function (t) {
        return new Promise((resolve) => setTimeout(resolve, t))
      }
      const today = new Date().toISOString().slice(0, 10).replace(/-/gi, '')
      const columns = []
      let keylist = []
      if (dataList.length > 0) {
        keylist = Object.keys(dataList[0])
      }
      for (let i = 0; i < header.length; i++) {
        columns.push({
          header: header[i],
          key: keylist[i],
          width: (widthList[i] || 15 * 5.5) / 5.5,
          bold: true,
        })
      }
      let i = 0
      let writer = null
      const splitFileCount = Math.ceil(dataList.length / rowPerFile)
      try {
        if (dataList.length > 0) {
          while (dataList.length) {
            i++
            const dataSlice = dataList.splice(0, rowPerFile)
            const workbook = new Excel.Workbook()
            const sheet = workbook.addWorksheet(sheetNm)

            // sheet.columns 에 직접 push 할 경우 에러 발생 함
            sheet.columns = columns
            sheet.getRow(1).font = { bold: true }
            sheet.addRows(dataSlice)

            // stringTypeColumns에 해당하는 컬럼은 type을 string으로 변환
            if (stringTypeColumns.length > 0) {
              stringTypeColumns.forEach((column) => {
                sheet.getColumn(column).numFmt = '@'
              })
            }

            const fileNo
              = splitFileCount > 1
                ? '_[' + i + ' of ' + splitFileCount + ']'
                : ''
            const file = fileName + '_' + today + fileNo + '.xlsx'

            const fileStream = streamSaver.createWriteStream(file, {
              writableStrategy: new ByteLengthQueuingStrategy({
                highWaterMark: 1,
              }),
              readableStrategy: new ByteLengthQueuingStrategy({
                highWaterMark: 1,
              }),
            })
            writer = fileStream.getWriter()
            const unload = function () {
              writer.abort()
            }
            window.addEventListener('unload', unload)
            await workbook.xlsx.write(writer)
            writer.close()
            window.removeEventListener('unload', unload)
            await sleep(300)
          }
        } else {
          const dataSlice = dataList.splice(0, rowPerFile)
          const workbook = new Excel.Workbook()
          const sheet = workbook.addWorksheet(sheetNm)

          // sheet.columns 에 직접 push 할 경우 에러 발생 함
          sheet.columns = columns
          sheet.getRow(1).font = { bold: true }
          sheet.addRows(dataSlice)
          const fileNo
            = splitFileCount > 1 ? '_[' + i + ' of ' + splitFileCount + ']' : ''
          const file = fileName + '_' + today + fileNo + '.xlsx'

          const fileStream = streamSaver.createWriteStream(file, {
            writableStrategy: new ByteLengthQueuingStrategy({
              highWaterMark: 1,
            }),
            readableStrategy: new ByteLengthQueuingStrategy({
              highWaterMark: 1,
            }),
          })
          writer = fileStream.getWriter()
          const unload = function () {
            writer.abort()
          }
          window.addEventListener('unload', unload)
          await workbook.xlsx.write(writer)
          writer.close()
          window.removeEventListener('unload', unload)
          await sleep(300)
        }
        if (lastAlert) {
          // setTimeout(async () => {
          await this.alert({
            title: '엑셀 다운로드',
            message: '다운로드가 완료되었습니다.',
          })
          // }, 500)
        }
      } catch (error) {
        if (writer) {
          writer.abort()
        }
        throw error
      }
    },
    async fileDownload (response, fileName = '') {
      try {
        // 파일명이 없을경우 기본 이름으로 설정
        if (fileName == '') {
          fileName = response.data.fileName
        }
        const fileResponse = await fetch(response.data.fileUrl)
        const writableStream = streamSaver.createWriteStream(fileName, {
          size: fileResponse.headers.get('Content-Length'),
        })

        const readableStream = fileResponse.body
        const writer = readableStream.pipeTo(writableStream)

        await writer
      } catch (e) {
        await this.alert({
          title: '파일 다운로드',
          message: '파일 다운로드를 실패하였습니다.',
        })
      }
    },
    async fileDowloadURI (url) {
      window.open(url)
    },
  },
})
