import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import memberRoutes from '@/router/memberRoutes'

Vue.use(VueRouter)
const Login = () => import('@/components/login/Login')
const routes = [
  {
    path: '*',
    name: 'Error404',
    component: () => import('@/components/common/error/Error404'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '*',
    name: 'Error403',
    component: () => import('@/components/common/error/Error403'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/',
    name: 'Main',
    ...(
      process.env.VUE_APP_MODE == 'local'
        ? {
            component: () => import('@/components/Main'),
          }
        : {
            redirect: '/member-management/member',
          }
    ),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      mode: 'login',
      redirect: '/',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
    beforeEnter: async (to, from, next) => {
      const result = await store.dispatch('user/logout')
      if (result == 'error') {
        router.app.$nextTick(() => {
          router.app.alert({
            message: '로그아웃에 실패 했습니다. 인터넷 연결을 확인 해 주세요.',
          })
        })
        next(from.fullPath)
      } else {
        if (to.query.returnPage) {
          next({
            path: '/login',
            query: { redirect: from.fullPath },
          })
        } else {
          next('/login')
        }
      }
    },
  },
  ...memberRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// 엑세스 토큰 보유여부 확인
const isLogined = function () {
  return !!store.state.user.accessToken
}

// 토큰 내 메뉴 권한 확인 ( accessToken:auth:r 권한에 해당 메뉴 번호가 포함 되었는지 확인)
const isReadable = function (to) {
  // 비로그인 단독 호출시 버그 방지 (return 권한 없음)
  if (!isLogined()) return false
  return true
  // const menuId = store.getters.pathToMenu(to.path)?.menuNo
  // return store.getters['user/accessibleMenus'].includes(menuId)
}

// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLogined()) {
      // 페이지 접근 권한 확인
      if (isReadable(to)) {
        // 권한 있음
        next()
      } else {
        // 권한 없음 403
        next({ name: 'Error403', params: [to.path] })
      }
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('user/refresh')) {
        // 리프레시 시도 성공
        // 페이지 접근 권한 확인
        if (isReadable(to)) {
          // 권한 있음
          next()
        } else {
          // 권한 없음
          next({ name: 'Error403', params: [to.path] })
        }
      // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      } else {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        })
      }
    }
  // 권한이 필요 없는 페이지
  } else {
    // 이동
    // await store.dispatch('user/refresh') // 리프레시까지는 말고 메뉴 갱신 api 만 해도 되는데 일단 급하니까 그냥 주석 해제
    next()
  }
})

export default router
