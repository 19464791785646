
export default [
  {
    path: '/member-management/member',
    name: 'member',
    component: () => import('@/components/member-management/member/Member'),
    meta: {
      requiresAuth: true,
      pageTitle: '멤버 목록',
      menuFullName: '멤버 관리 > 멤버 목록',
    },
  },
  {
    path: '/member-management/member/registration',
    name: 'memberRegistration',
    component: () =>
      import('@/components/member-management/member/Registration'),
    meta: {
      requiresAuth: true,
      pageTitle: '멤버 등록',
      menuFullName: '멤버 관리 > 멤버 등록',
    },
  },
  {
    path: '/member-management/member/:memberNo/edit',
    name: 'memberEdit',
    component: () => import('@/components/member-management/member/Edit'),
    meta: {
      requiresAuth: true,
      pageTitle: '멤버 수정',
      menuFullName: '멤버 관리 > 멤버 수정',
    },
  },
  {
    path: '/member-management/attendance',
    name: 'attendance',
    component: () => import('@/components/member-management/Attendance'),
    meta: {
      requiresAuth: true,
      pageTitle: '근태 관리',
      menuFullName: '멤버 관리 > 근태 관리',
    },
  },
]
