const getDefaultState = () => {
  return {
    members: [],
    member: {},
    codes: {},
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    members: (state) => state.members,
    member: (state) => state.member,
    codes: (state) => state.codes,
    totalCount: (state) => state.count,
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 50
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setMembersResponse (state, data) {
      state.members = data.members
      state.count = data.totalCount
      state.codes = data.codes
    },
    setMemberResponse (state, data) {
      state.member = data.member
      state.codes = data.codes
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getMembers ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.OMS_API_URL}members`,
          payload,
        )
        commit('setMembersResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async getMember ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.OMS_API_URL}member/${payload.memberNo}`,
        )
        commit('setMemberResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async registrationMember ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.OMS_API_URL}member`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async registrationMembers ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.OMS_API_URL}members`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async modificationMember ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.OMS_API_URL}member/${payload.memberNo}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async deletionMember ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.OMS_API_URL}member/${payload.memberNo}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async getMemberAttendances ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.OMS_API_URL}members/attendances`, payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
