import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PortalVue from 'portal-vue'

import SpCounter from '@/components/common/SpCounter'
import SpLimiter from '@/components/common/SpLimiter'
import SpMultipleSelect from '@/components/common/input/SpMultipleSelect'
import SpInput from '@/components/common/input/SpInput'
import SpFileInput from '@/components/common/input/SpFileInput'
import SpMaskingInput from '@/components/common/input/SpMaskingInput'
import SpTextarea from '@/components/common/input/SpTextarea'
import SpButton from '@/components/common/input/SpButton'
import SpCheckbox from '@/components/common/input/SpCheckbox'
import SpSwitchCheckbox from '@/components/common/input/SpSwitchCheckbox'
import SpRadio from '@/components/common/input/SpRadio'
import SpSelect from '@/components/common/input/SpSelect'
import SpRangeDatepicker from '@/components/common/input/SpRangeDatepicker'
import SpSingleDatepicker from '@/components/common/input/SpSingleDatepicker'
import SpTable from '@/components/common/SpTable'
import SpTabbedPanel from '@/components/common/SpTabbedPanel'
import SpResizable from '@/directive/SpResizable'
import FitInput from '@/components/common/input/FitInput'
import SpTooltip from '@/components/common/SpTooltip'
import SpSelectDate from '@/components/common/SpSelectDate'
import Popup from '@/components/common/Popup'
import Pagination from '@/components/common/Pagination'
import isOutside from '@/directive/isOutside'
import validity from '@/directive/Validity'
import VCalendar from 'v-calendar'
import SpBadge from '@/components/common/SpBadge'
import SpFileChooser from '@/components/common/SpFileChooser'
import IconRequired from '@/components/common/IconRequired'
import IconNew from '@/components/common/IconNew'
import VueKonva from 'vue-konva'
import Multiselect from 'vue-multiselect'
// import './registerServiceWorker'

import './mixin/Common'
import './mixin/KonvaLibs'
import './assets/scss/common.scss'
// Konva.hitOnDragEnabled = true

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/] // https://ionic.io/ionicons

// const API_TIMEOUT = 35000
Vue.prototype.$http = axios.create({
  // timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

Vue.use(SpResizable)
Vue.use(isOutside)
Vue.use(validity)
Vue.use(PortalVue)
Vue.use(VCalendar)
Vue.use(VueKonva)
Vue.component('multiselect', Multiselect)
Vue.component('Pagination', Pagination)
Vue.component('Popup', Popup)
Vue.component('SpSelect', SpSelect)
Vue.component('SpCounter', SpCounter)
Vue.component('SpLimiter', SpLimiter)
Vue.component('SpMultipleSelect', SpMultipleSelect)
Vue.component('SpRadio', SpRadio)
Vue.component('SpCheckbox', SpCheckbox)
Vue.component('SpSwitchCheckbox', SpSwitchCheckbox)
Vue.component('SpInput', SpInput)
Vue.component('SpFileInput', SpFileInput)
Vue.component('SpTooltip', SpTooltip)
Vue.component('SpSelectDate', SpSelectDate)
Vue.component('SpMaskingInput', SpMaskingInput)
Vue.component('SpTextarea', SpTextarea)
Vue.component('SpButton', SpButton)
Vue.component('SpRangeDatepicker', SpRangeDatepicker)
Vue.component('SpSingleDatepicker', SpSingleDatepicker)
Vue.component('SpTable', SpTable)
Vue.component('SpTabbedPanel', SpTabbedPanel)
Vue.component('FitInput', FitInput)
Vue.component('IconRequired', IconRequired)
Vue.component('IconNew', IconNew)
Vue.component('SpBadge', SpBadge)
Vue.component('SpFileChooser', SpFileChooser)

const v = new Vue({
  router,
  store,
  created () {
    this.$http.interceptors.request.use(
      (config) => {
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        store.commit('addCancelToken', source)
        store.commit('increaseRequestCount')
        return config
      },
      function (error) {
        return Promise.reject(error)
      },
    )
    this.$http.interceptors.response.use(
      this.responseSuccess,
      this.responseError,
    )
  },
  render: h => h(App),
})

router.onReady(async () => {
  await store.dispatch('user/refresh')
  v.$mount('#app')
})
