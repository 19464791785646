<template>
    <div class="gap">
      <div class="wrap-input">
        <input
        ref="input"
        :class="{ plaintext: plaintext }"
        :type="eye ? 'text' : 'password'"
        :value="formattedValue"
        :id="uuid"
        @input="input"
        v-on="lisnters"
        v-bind="attrs"
        :disabled="plaintext || disabled" />
        <button type="button" class="toggle" @click.prevent.stop="eye = !eye">
            <span class="view-icon">
                <ion-icon :name="eye ? 'eye' : 'eye-off'"></ion-icon>
            </span>
        </button>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SpMaskingInput',
  inheritAttrs: false,
  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
    isShow: { type: Boolean, default: false },
    type: { type: String, default: 'password', required: false },
    plaintext: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    breakPoints: { type: Array, default: () => [] },
    delimiter: { type: String, default: '' },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    },
    focus () {
      this.$refs.input.focus()
    },
  },
  watch: {
    isShow (newValue) {
      this.eye = newValue
    },
  },
  computed: {
    eye: {
      get () {
        return this.isShow
      },
      set (newValue) {
        this.$emit('update:isShow', newValue)
      },
    },
    lisnters () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
    formattedValue () {
      let formatted = this.value
      if (this.breakPoints.length && this.delimiter != '') {
        const splitStringByBreakPoints = (str, breakPoints) => {
          const result = []
          let startIndex = 0
          for (let i = 0; i < breakPoints.length; i++) {
            const breakPoint = breakPoints[i]
            const substring = str.substr(startIndex, breakPoint)
            if (substring.length) {
              result.push(substring)
            }
            startIndex += breakPoint
          }
          const remaining = str.substr(startIndex)
          if (remaining.length) {
            result.push(remaining)
          }
          return result
        }
        const realNumber = this.value.replaceAll(this.delimiter, '')
        const delimiteredNumber = splitStringByBreakPoints(realNumber, this.breakPoints)
        formatted = delimiteredNumber.join(this.delimiter)
      }
      return formatted
    },
  },
}
</script>

<style lang="scss" scoped>
.gap {
  max-width: 100%;
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}
.wrap-input {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: calc(100% - 5px);
  box-sizing: border-box;
}
input {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  text-indent: 8px;
  width: 100%;
  height: auto;
  font-family: inherit;
  padding: 4px 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 4px 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &.plaintext {
    padding: 4px 0;
    border-color: transparent;
    &:disabled {
      background-color: transparent;
    }
  }
}
.toggle {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
input[type=number] {
  text-align: right;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}
input:focus {
  border-color: var(--theme-primary-color);
  animation: shadow 0.1s ease-in-out forwards;
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
    /* box-shadow: 0 0 6px red; */
  }
}
.hide-caret {
  caret-color: transparent !important;
}
</style>
