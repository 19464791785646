import { decode } from 'js-base64'
const JWT_EXPIRE_TIME = 1000 * 3600

export default {
  namespaced: true,
  state: {
    accessToken: null,
  },
  getters: {
    userName: (state) => {
      if (state.accessToken) {
        return JSON.parse(decode(state.accessToken.split('.')[1])).userName
      }
      return ''
    },
    accessibleMenus: (state) => {
      if (state.accessToken) {
        return (
          JSON.parse(decode(state.accessToken.split('.')[1]))?.auth?.r || []
        )
      }
      return []
    },
    accountNo: (state) => {
      if (state.accessToken) {
        return JSON.parse(decode(state.accessToken.split('.')[1])).no
      }
      return ''
    },
  },
  mutations: {
    setAccessToken (state, token) {
      state.accessToken = token
    },
  },
  actions: {
    // 2fa 인증 생성
    async login ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.OMS_API_URL}login`,
          payload,
        )
        await dispatch('onLoginSuccess', response)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // refresh-token 삭제 (로그아웃)
    async logout ({ commit, getters, state }) {
      try {
        const payload = {
          accountNo: getters.accountNo,
        }
        await this._vm.post(
          `${this._vm.OMS_API_URL}logout`,
          payload,
        )
      } catch (error) {
        return 'error'
      } finally {
        commit('setAccessToken', null)
        commit('setTree', {}, { root: true })
        commit('setPathToMenu', {}, { root: true })
      }
      // 로그아웃 화면 결정용
      return 'ok'
    },
    // 로그인 성공 처리 (토큰 저장 / 자동 갱신 등록)
    async onLoginSuccess ({ rootState, commit, dispatch }, payload) {
      // const response = null
      try {
        commit('setAccessToken', payload.data.accessToken)
        // response = await this._vm.get(
        //   `${this._vm.OMS_API_URL}system/menu`,
        // )
        // commit('setTree', response.data.tree, { root: true })
        // commit('setPathToMenu', response.data.pathToMenu, { root: true })

        // Silent Refresh
        setTimeout(() => {
          dispatch('refresh')
        }, JWT_EXPIRE_TIME)
      } catch (error) {
        return false
      }
      return true
    },
    // 토큰 갱신
    async refresh ({ dispatch }) {
      try {
        const response = await this._vm.post(
          `${this._vm.OMS_API_URL}refresh`,
        )
        await dispatch('onLoginSuccess', response)
        return true
      } catch (error) {
        return false
      }
    },
  },
}
