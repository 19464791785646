<template>
  <popup
  :closeOnClickOutside="true"
  :title="`파일 ${uploadKeyPath ? '업로드': '선택'}`"
  :description="`${uploadKeyPath ? '업로드 할 ': ''}파일을 선택해 주세요.`"
  @close="() => {
    this.$emit('close')
    this.$emit('file', null)
  }">
    <div class="button-group" style="position:relative">
      <sp-input :value="fileName" readonly style="width: 400px"/>
      <input
      ref="fileInput"
      @change="fileChoose"
      type="file"
      style="
      border-radius: 5px;
      box-sizing: border-box;
      height:28px;
      margin:2.5px 2.5px 2.5px 2.5px;
      border: 4px solid red;
      padding: 0;
      position:absolute;
      width :100%;
      opacity: 0;
      right:0"/>
      <sp-button
      style="pointer-events: none; width :100px; opacity: 1"
      class="primary">파일 찾기</sp-button>
    </div>
    <div v-show="preview">
      <div v-if="file?.type.startsWith('image')">
        <img :src="preview" width="500"/>
      </div>
      <div v-if="file?.type == 'application/pdf'">
        <embed :src="preview" width="500" height="375" type="application/pdf">
      </div>
      <div style="text-align:right;">
        <sp-button type="button" @click="uploadFile" class="primary big">{{ uploadKeyPath ? '업로드' : '열기' }}</sp-button>
      </div>
    </div>
  </popup>
</template>
<script>
import AWS from 'aws-sdk'
import dateFormat from 'dateformat'
export default {
  props: {
    allowedExt: {
      type: Array,
      default: () => [],
    },
    maxFileSize: {
      type: Number,
      default: 1048576,
    },
    uploadKeyPath: {
      type: String,
    },
  },
  name: 'SpFileChooser',
  created () {

  },
  data () {
    return {
      file: null,
      preview: '',
      fileName: '',
      displayFileChooser: false,
    }
  },
  computed: {
    allowedExtLower () {
      return this.allowedExt.map(e => e.toLowerCase())
    },
  },
  methods: {
    async fileChoose (e) {
      const file = e.target.files[0]
      if (file) {
        const ext = file.name.split('.').pop().toLowerCase()
        const size = file.size
        // fileExt
        if (this.allowedExt.length) {
          if (!this.allowedExtLower.includes(ext)) {
            await this.alert({ message: `${this.allowedExt.join(', ')} 파일만 등록 가능합니다.` })
            this.$refs.fileInput.value = null
            return
          }
        }
        // fileSize
        if (size > this.maxFileSize) {
          await this.alert({ message: `최대 ${this.formatBytes(this.maxFileSize)} 까지 등록 가능합니다.` })
          this.$refs.fileInput.value = null
          return
        }
        this.preview = URL.createObjectURL(file)
      } else {
        this.preview = ''
        this.fileName = ''
      }
      this.fileName = file.name
      this.file = file
    },
    async uploadFile () {
      if (!this.file) {
        await this.alert({ message: '파일을 선택해 주세요.' })
        return
      }
      if (!this.uploadKeyPath) {
        this.$emit('file', this.file)
        this.$emit('close')
        return
      }
      try {
        this.$store.commit('increaseRequestCount')
        const currdate = new Date()
        const hms = dateFormat(currdate, 'HHMMss')
        const ext = this.file.name.split('.').pop().toLowerCase()
        const fileName = `${this.uuidv4()}-${hms}.${ext}`
        const env
          = process.env.VUE_APP_MODE === 'production'
            ? 'production'
            : 'development/dev'
        const uploadKey = `${env}/${this.uploadKeyPath}/${fileName}`

        AWS.config.update({
          region: process.env.VUE_APP_BUCKET_REGION,
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
          }),
        })
        const s3 = new AWS.S3({
          apiVersion: '2006-03-01',
          params: { Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET },
        })

        await s3.upload({
          Key: uploadKey,
          Body: this.file,
          ContentType: this.file.type,
        }).promise()

        const commonFilePayload = {
          serviceType: 'sparkplus-platform',
          displayFileName: this.file.name,
          fileName,
          filePath: uploadKey,
          awsS3Url: `${process.env.VUE_APP_S3_STATIC_URL}/${uploadKey.split('/').splice(1).join('/')}`,
          fileFormatType: this.file.type,
          fileExtension: ext,
          fileSize: this.file.size,
          displayOrder: 1,
        }
        const response = await this.post(`${this.OMS_API_URL}common/file/post`, commonFilePayload)
        const fileId = response.data

        this.$emit('uploaded', {
          fileId,
          ...commonFilePayload,
        })
        this.$emit('close')

      } catch (error) {
        await this.alert({ message: '업로드중 문제가 발생했습니다.\n다시 시도해 주세요.' })

      } finally {
        this.$store.commit('decreaseRequestCount')
      }
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
