<template>
<div class="gap">
  <div class="selectbox">
      <select
      :id="uuid"
      :value="value"
      @input="input"
      @click="input"
      v-on="listeners"
      v-bind="attrs">
          <option v-for="v in limits" :value="v" :key="v">{{ v }}</option>
      </select>
  </div>
</div>
</template>

<script>
export default {
  name: 'SpLimiter',
  inheritAttrs: false,
  props: {
    value: { type: null, default: 5 },
    id: { type: String, default: '' },
    limits: {
      type: Array,
      default: () => [
        5,
        10,
        20,
        30,
        50,
        100,
      ],
    },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
}
</script>

<style lang="scss" scoped>
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 120px;
  min-width: 20px;
  box-sizing: border-box;
}
.selectbox {
  position: relative;
  left: 2.5px;
  display: inline-block;
  width: 115px;
  box-sizing: border-box;
}

.selectbox:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 9px;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--theme-primary-color);
}

.selectbox select {
  padding: 4px 0;
  margin: 4px 0;
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  line-height: 16px;
  text-indent: 9px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectbox select:focus {
  border-color: var(--theme-primary-color);
  animation: shadow 0.1s ease-in-out forwards;
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
  }
}
select option[value=""][disabled] {
  display: none;
}
</style>
