
<template>
  <div class="wrap">
    <sp-select v-model="selectedYear" v-validity="!selectedYear && '사용월(년도)을 선택하세요'" :disabled="disabled">
      <option value="">선택</option>
      <option v-for="(item, idx) in yearData" :key="idx" :value="item.year">{{ item.year }}</option>
    </sp-select>
    <sp-select v-model="selectedMonth" v-validity="!selectedMonth && '사용월(월)을 선택하세요'" :disabled="disabled">
      <option value="">선택</option>
      <option v-for="(month, idx) in monthOptions" :key="idx" :value="month">{{ month }}</option>
    </sp-select>
  </div>
</template>

<script>
import SpInput from './input/SpInput.vue'
export default {
  components: { SpInput },
  name: 'SpSelectDate',
  inheritAttrs: false,
  props: {
    year: {
      type: [Number, String],
      default: new Date().getFullYear(),
    },
    month: {
      type: [Number, String],
      default: new Date().getMonth() + 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      first: true,
      selectedYear: null,
      selectedMonth: null,
      propsYear: this.defaultYear(),
      propsMonth: this.defaultMonth(),
      yearData: [],
    }
  },
  mounted () {
    this.setDate('year', 50)

    for (let i = 0; i < 10; i++) {
      const year = this.propsYear + i
      const months = []
      if (i === 0) {
        for (let j = this.propsMonth; j <= 12; j++) {
          months.push(j)
        }
      } else {
        for (let j = 1; j <= 12; j++) {
          const num = `${j}`
          months.push(num.padStart(2, '0')) // 01,02,03 형태로 10보다 작은수일경우 0을 앞에 하나붙여서 두자리 만들어주는 부분
        }
      }
      this.yearData.push({
        year,
        months,
      })
    }
  },
  methods: {
    setDate (type, time) {
      setTimeout(() => {
        if (type === 'year') {
          this.selectedYear = this.year
        } else if (type === 'month') {
          this.selectedMonth = this.month
          this.first = false
        }
      }, time)
    },
    defaultYear () {
      let propsData = Number(this.year)
      if (!propsData) {
        propsData = new Date().getFullYear()
      }

      return Number(this.month) === 1 ? Number(propsData) - 1 : Number(propsData)
    },
    defaultMonth () {
      let propsData = Number(this.month)
      if (!propsData) {
        propsData = new Date().getMonth() + 1
      }

      return Number(propsData) === 1 ? 12 : Number(propsData) - 1
    },
  },
  computed: {
    monthOptions () {
      if (this.selectedYear) {
        const yearData = this.yearData.find(i => i.year === Number(this.selectedYear))
        return yearData ? yearData.months : []
      }
      return []
    },
  },
  watch: {
    selectedYear (newValue) {
      if (newValue) {
        if (!(this.month && this.first)) {
          this.selectedMonth = ''
        } else {
          this.setDate('month', 50)
        }
        this.$emit('update:year', newValue)
      }
    },
    selectedMonth (newValue) {
      if (newValue) {
        this.$emit('update:month', newValue)
      }
    },
    year (newValue) {
      this.setDate('year', 50)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap{
  display: flex;
  flex-direction: row;
}
</style>
