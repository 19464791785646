const getDefaultState = () => {
  return {
    categoryDates: [],
    recorded: [],
    sended: [],
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 50
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.categoryDates = data.categoryDates
      state.recorded = data.recorded
      state.sended = data.sended
    },
    setOriginFilter (state, filter) {
      if (filter) {
        state.originFilter = JSON.parse(JSON.stringify(filter))
      }
    },
  },
  actions: {
    async getStats ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.OMS_API_URL}stats`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
